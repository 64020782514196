import tippy, { Props as TippyProps } from 'tippy.js';
// TODO: Figure out types to use SingleTarget and MultipleTargets from tippy.js

/**
 * Initialize Tippy tooltips
 * @param elem Optional target(s) to initialize tippy on, otherwise does all elements with data-tippy-content
 */
export default (target?: any) => {
  const options = {
    arrow: false,
    theme: 'reids',
    offset: [0, 12],
    trigger: 'click',
    allowHTML: true,
  } as TippyProps;

  if (target) {
    tippy(target, options);
  } else {
    tippy('[data-tippy-content]', options);
  }
};
